import { MentionLegalesContainer } from "./MentionsLegalesStyle";
import { Title1, Title2, BodyText } from "../../globalStyle";

export default function MentionsLegales() {
  return (
    <MentionLegalesContainer>
      <Title1>Mentions légales</Title1>
      <div className="contact-body">
        <Title2>Rhizoome</Title2>
        <BodyText>
          Florine Davi <br />
          1 RLE DES ROUGEUX, 95280 JOUY-LE-MOUTIER <br />
          Tel : 06 70 14 23 85 <br />
          Mail : rhizoome95@gmail.com <br />
          FR15919277343 <br />
          SIRET : 91927734300019
        </BodyText>
        <Title2>Hébergeur</Title2>
        <BodyText>
          HOSTINGER INTERNATIONAL LTD <br />
          61 Rue Lordou Vironos <br />
          6023 Larnaca, Chypre <br />
          Joignable par le moyen suivant :https://www.hostinger.fr/contact.
        </BodyText>
        <Title2>Credit photo</Title2>
        <BodyText>
          Merci à Jean Made pour les photos d'insectes et d’escargot en
          couverture de ce site. <br />
          <a href="https://instagram.com/madejean?igshid=MmIzYWVlNDQ5Yg==">
            Voir son instagram
          </a>
        </BodyText>
        <Title2>Caractère typographique</Title2>
        <BodyText>
          Le caractère Faune utilisé sur ce site a été créé par Alice Savoie
          dans le cadre d’une commande du Centre national des arts plastiques en
          partenariat avec le Groupe Imprimerie Nationale <br />
          <a href="https://www.cnap.fr/sites/faune/">
            Voir le site de ce caractère
          </a>
        </BodyText>
        <Title2>Données personnelles</Title2>
        <BodyText>
          Aucune information personnelle n’est collectée à votre insu par
          Rhizoome
        </BodyText>
        <Title2>Propriété intellectuelle</Title2>
        <BodyText>
          La propriété intellectuelle de ce site, y compris sa structure
          générale ainsi que son contenu tel que textes, photos, images,
          appartient à Florine Davi. Toute reproduction ou représentation,
          partielle ou totale, du contenu de ce site, ou de l'un de ses
          composants, sans l'autorisation de Florine Davi, est interdite. Une
          telle action constitue une contrefaçon, passible de sanctions en vertu
          des articles L.335-2 et suivants du Code de la propriété
          intellectuelle.
        </BodyText>
      </div>
    </MentionLegalesContainer>
  );
}
