const colors = {
  white: "white",
  darkGray: "#55604d",
  offWhite: "#fff7e9",
  cream: "#ffe6ba",
  green: "#87bd28",
  darkGreen: "#65A20A",
  lightGreen: "#9FCB57",
  anisGreen: "	#C5C300",
  orange: "#E95C13",
  darkBrown: "#704111",
  brown: "#92551C",
  lightBrown: "#af7634",
  lightGrayBrown: "#c79f72",
  yellow: "#F9B036",
  blue: "#019097",
  purple: "#5B67AE",
  translucentOrange: "rgba(255,247,233, 0.85)",
  translucentGreen: "rgba(243,245,235, 0.85)",
  translucentYellow: "rgba(251,250,232, 0.85)",
  translucentGray: "rgba(247, 239, 224, 0.8)",
  translucentOffWhite: "rgba(255, 247, 233, 0.95)",
  translucentWhite: "rgba(255, 255, 255, 0.2)",
  whiteGreen: "rgba(135, 189, 40, 0.20)",
  whiteBrown: "rgba(146, 85, 28, 0.15)",
  whiteOrange: "rgba(233, 92, 19, 0.2)",
  whiteYellow: "rgba(249, 176, 54, 0.20)",
  roundImageLink: "rgba(175,118,52,0.8)",
  infoBox: "rgba(251,250,232, 0.60)",

  animationTopicColors: {
    autourDeLaFerme: ["	#935D26", "#AF7634", "rgba(146, 85, 28, 0.15)"],
    autourDeLaNature: ["#66A316", "#87BD28", "rgba(135, 189, 40, 0.20)"],
    autourDuDD: ["#CF3D02", "#E95C13", "rgba(233, 92, 19, 0.2)"],
  },
  couleurTag: {
    "ADAPTABLE TOUS PUBLIC": "	#C5C300",
    ÉCOLES: "#5B67AE",
    "CENTRE DE LOISIRS": "	#019097",
    PARTICULIERS: "#F9B036",
    "STRUCTURES DE L'ÉDUCATION SPÉCIALISÉE": "#5B67AE",
    BIBLIOTHEQUES: "#C5C300",
  },
};

export default colors;
