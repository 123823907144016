import styled from "styled-components";
import colors from "../../utils/style/colors";

export const MentionLegalesContainer = styled.div`
  background-color: ${colors.offWhite};
  padding: 0 5% 50px;
  & .contact-body {
    margin: 50px auto;
    width: 70%;
    & p {
      margin-bottom: 50px;
      transform: scale(1.2);
      transform-origin: left;
      color: ${colors.brown};
      transition: all 500ms;
      & a {
        color: ${colors.brown};
        filter: brightness(0.8);
        font-size: 0.8em;
      }
      &:hover {
        filter: brightness(0.8);
      }
    }
    & h2 {
      margin-bottom: 20px;
    }
  }
`;
